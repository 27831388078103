import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

const getAnAggregatorOnboardingParameters = async ({
  aggregator_id,
  productType,
  documentOwnerType,
}) => {
  const { data } = await getDataWithDotNet(
    `${ajaxEndpoints.GET_AN_AGGREGATOR_ONBOARDING_DETAILS_MULTIPLE}?aggregator_id=${aggregator_id}&productType=${productType}&documentOwnerType=${documentOwnerType}`
  );
  return data;
};

export default function useAnAggregatorOnboardingParameters({
  aggregator_id,
  productType,
  documentOwnerType,
}) {
  return useQuery(
    [
      { aggregator_id, productType, documentOwnerType },
      "getAnAggregatorOnboardingParameters",
    ],
    getAnAggregatorOnboardingParameters,
    {
      refetchOnWindowFocus: true,
    }
  );
}
