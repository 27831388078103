import { Dropdown, MenuProps, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { BASE_URL } from "../../../../../appConstants";
import { useState } from "react";
import useCreditBureauAllSubmissions from "../../../../../custom-hooks/useCreditBureauAllSubmissions";
import moment from "moment";
import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";
import Paginator from "../../../../../components/Pagination/Pagination.component";
import { DOWNLOAD_CREDIT_BUREAU_REPORT } from "../../../../../api/ajax-endpoints";
import { appInsights } from "../../../../../config/appInsights";
import { getTokenFromLocalStorage } from "../../../../../helpers/localStorage";
import { useHistory } from "react-router-dom";
import { errorHandler } from "../../../../../helpers/errorHandler";
import { postData } from "../../../../../newApis/apiMethods";
import Loader from "../../../../../NewComponents/Loader/Loader.component";
import TableDataLoading from "../../../../../NewComponents/TableDataLoading/TableDataLoading.component";
type reportList = {
  status: string;
  reportDate: string;
  providerId: string;
  excelFileUrl: string;
  jsonFileUrl: string;
  reportId: string;
};
const GenerateCreditBureauReport = () => {
  const [loadingGenerateReport, setLoadingGenerateReport] = useState(false);
  const [errorGenerateReport, setErrorGenerateReport] = useState<string | null>(
    null
  );
  const [successGenerateReport, setSuccessGenerateReport] = useState<
    string | null
  >(null);
  const [count, setCount] = useState(null);
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [loadingSubmitReport, setLoadingSubmitReport] = useState(false);
  const [errorSubmitReport, setErrorSubmitReport] = useState<string | null>("");
  const [successSubmitReport, setSuccessSubmitReport] = useState<string | null>(
    ""
  );
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const [preState, setPreState] = useState({
    pageNumber: 1,
    size: 20,
  });

  const handleGenerateCreditBureauReport = async () => {
    setLoadingGenerateReport(true);
    setErrorGenerateReport(null);
    setSuccessGenerateReport(null);
    const reqBody = {};
    try {
      const response = await postData(
        ajaxEndpoints.GENERATE_CREDIT_BUREAU_REPORT,
        reqBody
      );

      setLoadingGenerateReport(false);

      setSuccessSubmitReport(response?.message);

      setTimeout(() => {
        setSuccessSubmitReport(null);
      }, 5000);
    } catch (error) {
      appInsights.trackException({
        exception: error as Error,
        properties: { fileName: "GenerateCreditBureauReport.tsx" },
      });
      setLoadingGenerateReport(false);

      setErrorGenerateReport(errorHandler(error));
    }
  };
  const {
    data: allCreditBureauSubmissions,
    isLoading: allCreditBureauSubmissionsLoading,
    error: allCreditBureauSubmissionsError,
    refetch: refetchAllCreditBureauSubmissions,
  } = useCreditBureauAllSubmissions({
    page: preState.pageNumber,
    pageSize: preState.size,
  });
  const history = useHistory();

  if (allCreditBureauSubmissions) {
    if (count === null) {
      setCount(allCreditBureauSubmissions?.totalCount);
    }
  }
  const handleDownloadStatement = async (
    currentUrl: string,
    reportId: string
  ) => {
    setDownloading(true);
    let token = getTokenFromLocalStorage();
    fetch(
      BASE_URL +
        `${DOWNLOAD_CREDIT_BUREAU_REPORT}?ReportId=${reportId}&DocumentFilePath=${currentUrl}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: "Token " + token,
        },
      }
    )
      .then((response) => {
        setDownloading(false);
        if (response.status === 200 || response.status === 201) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;

            let filename =
              currentUrl.split("/").pop() || "credit_bureau_report";
            if (!filename.includes(".")) {
              filename += currentUrl.endsWith("xlsx") ? ".xlsx" : ".txt";
            }

            link.setAttribute("download", filename.trim());
            link.click();

            window.URL.revokeObjectURL(url);
          });
        }

        if (response.status === 403 || response.status === 401) {
          localStorage.removeItem("sso_auth_token");
          history.push("/login");
        }
      })
      .catch((error) => {
        setErrorOccurred(true);
        setTimeout(() => {
          setErrorOccurred(false);
        }, 3000);
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "GenerateCreditBureauReport.tsx",
          },
        });
        setDownloading(false);
      });
  };

  const generateDropdownItems = (
    reportId: string,
    excelUrl: string,
    jsonUrl: string,
    currentIndex: number
  ): MenuProps["items"] => [
    {
      key: "excel",
      label: (
        <button
          onClick={() => {
            handleDownloadStatement(excelUrl, reportId);
            setCurrentId(currentIndex);
          }}
          className="btn advancly-btn"
        >
          Download Excel{" "}
          {currentIndex === currentId && downloading && <Loader />}
        </button>
      ),
    },
    {
      key: "json",
      label: (
        <button
          onClick={() => {
            handleDownloadStatement(jsonUrl, reportId);
            setCurrentId(currentIndex);
          }}
          className="btn advancly-btn"
        >
          Download JSON{" "}
          {currentIndex === currentId && downloading && <Loader />}
        </button>
      ),
    },
  ];
  const submitGenerateCreditBureauReport = async (reportId: string) => {
    setLoadingSubmitReport(true);
    setErrorSubmitReport(null);
    setSuccessSubmitReport(null);
    const reqBody = {
      report_id: reportId,
    };
    try {
      const response = await postData(
        ajaxEndpoints.SEND_CREDIT_BUREAU_REPORT,
        reqBody
      );
      setLoadingSubmitReport(false);
      setSuccessSubmitReport(response?.message);
    } catch (error) {
      appInsights.trackException({
        exception: error as Error,
        properties: { fileName: "GenerateCreditBureauReport.tsx" },
      });
      setLoadingSubmitReport(false);

      setErrorSubmitReport(errorHandler(error));
      setTimeout(() => {
        setErrorSubmitReport(null);
      }, 3000);
    }
  };
  return (
    <div>
      <div className="py-2 d-flex flex-column justify-content-center align-items-center w-100">
        {errorOccurred && (
          <p className="alert alert-danger col-md-6">
            An error occurred while downloading statement, please try again
          </p>
        )}
        {errorSubmitReport && (
          <p className="alert alert-danger col-md-6">{errorSubmitReport}</p>
        )}
        {errorGenerateReport && (
          <p className="alert alert-danger col-md-6">{errorGenerateReport}</p>
        )}
        {allCreditBureauSubmissionsError && (
          <h5 className="text-center text-danger my-5 text-uppercase font-weight-bold">
            {allCreditBureauSubmissionsError}
          </h5>
        )}
        {successGenerateReport && (
          <h5 className="alert alert-success my-5 text-uppercase font-weight-bold">
            {successGenerateReport}
          </h5>
        )}
        {successSubmitReport && (
          <p className="alert alert-success my-5 text-uppercase font-weight-bold">
            {successSubmitReport}
          </p>
        )}
      </div>

      <div className="py-3 pl-3 font-weight-bold">
        <h3>Generate Credit Bureau Report</h3>
      </div>
      <div className="d-flex justify-content-between pl-3 py-2">
        <button
          className="btn advancly-btn mt-3"
          onClick={handleGenerateCreditBureauReport}
        >
          {"Click to Generate Report"} {loadingGenerateReport && <Loader />}
        </button>
        <button
          className="btn advancly-btn mt-3"
          onClick={() => {
            refetchAllCreditBureauSubmissions();
          }}
        >
          Refresh Report
        </button>
      </div>

      <div className="pl-3">
        <table className="table-style">
          <thead>
            <tr>
              <th className="th-td-style th-style" scope="col">
                Report Date
              </th>
              <th className="th-td-style th-style" scope="col">
                Provider
              </th>
              <th className="th-td-style th-style" scope="col">
                Status
              </th>
              <th className="th-td-style th-style" scope="col">
                Download
              </th>
              <th className="th-td-style th-style" scope="col">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {allCreditBureauSubmissionsLoading ? (
              <TableDataLoading />
            ) : allCreditBureauSubmissions?.data ? (
              allCreditBureauSubmissions?.data?.map(
                (creditSubmissions: reportList, index: number) => {
                  const {
                    status,
                    reportDate,
                    providerId,
                    excelFileUrl,
                    jsonFileUrl,
                    reportId,
                  } = creditSubmissions;
                  return (
                    <tr key={index}>
                      <td className="th-td-style">
                        {moment(reportDate).format("DD/MM/YYYY")}
                      </td>
                      <td className="th-td-style">{providerId}</td>
                      <td className="th-td-style">{status}</td>
                      <td className="th-td-style cursor-pointer">
                        <Dropdown
                          menu={{
                            items: generateDropdownItems(
                              reportId,
                              excelFileUrl,
                              jsonFileUrl,
                              index
                            ),
                          }}
                        >
                          <Space>
                            Download
                            <DownOutlined />
                          </Space>
                        </Dropdown>
                      </td>
                      <td className="th-td-style">
                        <button
                          className="btn advancly-btn"
                          onClick={() => {
                            setCurrentId(index);
                            submitGenerateCreditBureauReport(reportId);
                          }}
                        >
                          Send Credit Report{" "}
                          {currentId === index && loadingSubmitReport && (
                            <Loader />
                          )}
                        </button>
                      </td>
                    </tr>
                  );
                }
              )
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                <p className=""> No Data Available</p>
              </div>
            )}
          </tbody>
        </table>
        <Paginator
          size={preState.size}
          page={preState.pageNumber}
          count={count || 0}
          changeCurrentPage={(pageNumber: number) =>
            setPreState({ ...preState, pageNumber })
          }
        />
      </div>
    </div>
  );
};

export default GenerateCreditBureauReport;
