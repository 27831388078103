// Redux Action CONSTANTS
import * as actionTypes from "../actions/actionTypes";

// Initial Application-wide Ecosystem State
const initialState = {
  disbursements: {
    disbursementCount: 0,
    disbursementSize: 50,
    disbursementPageNumber: 1,
    totalPage: 0,
    records: [],
    loading: false,
    error: null,
    borrowerName: "",
    loanId: null,
    bvn: "",
    rcNumber: "",
    aggregatorId: null,
    loanRef: "",
    startDate: "",
    endDate: "",
    refetch: false
  },
  repayments: {
    repaymentsTableData: {
      columns: [],
      rows: [],
    },
    records: [],
    count: 0,
    next_page: "",
    prev_page: "",
    loading: false,
    error: null,
  },
  loans: {
    count: 0,
    size: 20,
    pageNumber: 1,
    totalPage: 0,
    records: [],
    loading: false,
    error: null,
    startDate: "",
    endDate: "",
    searchValue: "",
    searchBy: "",
    is_disbursed: "",
    is_approved: "",
    loan_status: "",
    loanStartDate: "",
    loanEndDate: "",
    IsMigratedtoCBA: "",
    borrowerName: "",
    loanId: null,
    bvn: "",
    rcNumber: "",
    aggregatorId: null,
    loanRef: "",
  },
};
export const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_DISBURSEMENTS_START:
      return {
        ...state,
        disbursements: {
          ...state.disbursements,
          loading: true,
        },
      };

    case actionTypes.GET_DISBURSEMENTS_SUCCESS:
      return {
        ...state,
        disbursements: {
          ...state.disbursements,
          ...action.payload,
          loading: false,
          error: null,
        },
      };
    case actionTypes.GET_DISBURSEMENTS_FAILURE:
      return {
        ...state,
        disbursements: {
          ...state.disbursements,
          loading: false,
          error: action.error,
        },
      };
    case actionTypes.SET_DISBURSEMENT_FILTER_START_DATE:
      return {
        ...state,
        disbursements: {
          ...state.disbursements,
          startDate: action.payload,
        },
      };
    case actionTypes.SET_DISBURSEMENT_FILTER_END_DATE:
      return {
        ...state,
        disbursements: {
          ...state.disbursements,
          endDate: action.payload,
        },
      };
    case actionTypes.SET_DISBURSEMENT_COUNT:
      return {
        ...state,
        disbursements: {
          ...state.disbursements,
          disbursementCount: action.payload,
        },
      };
    case actionTypes.SET_DISBURSEMENT_PAGE_NUMBER:
      return {
        ...state,
        disbursements: {
          ...state.disbursements,
          disbursementPageNumber: action.payload,
        },
      };
    case actionTypes.SET_DISBURSEMENT_SIZE:
      return {
        ...state,
        disbursements: {
          ...state.disbursements,
          disbursementSize: action.payload,
        },
      };
    case actionTypes.SET_DISBURSEMENT_BORROWER_NAME:
      return {
        ...state,
        disbursements: {
          ...state.disbursements,
          borrowerName: action.payload,
        },
      };
    case actionTypes.SET_DISBURSEMENT_LOAN_ID:
      return {
        ...state,
        disbursements: {
          ...state.disbursements,
          loanId: action.payload,
        },
      };
    case actionTypes.SET_DISBURSEMENT_BVN:
      return {
        ...state,
        disbursements: {
          ...state.disbursements,
          bvn: action.payload,
        },
      };
    case actionTypes.SET_DISBURSEMENT_RC_NUMBER:
      return {
        ...state,
        disbursements: {
          ...state.disbursements,
          rcNumber: action.payload,
        },
      };
    case actionTypes.SET_DISBURSEMENT_AGGREGATOR_ID:
      return {
        ...state,
        disbursements: {
          ...state.disbursements,
          aggregatorId: action.payload,
        },
      };
    case actionTypes.SET_DISBURSEMENT_LOAN_REF:
      return {
        ...state,
        disbursements: {
          ...state.disbursements,
          loanRef: action.payload,
        },
      };
    case actionTypes.GET_REPAYMENTS_START:
      return {
        ...state,
        repayments: {
          ...state.repayments,
          loading: true,
          error: null,
        },
      };

    case actionTypes.GET_REPAYMENTS_SUCCESS:
      return {
        ...state,
        repayments: {
          ...state.repayments,
          ...action.payload,
          loading: false,
          error: null,
        },
      };

    case actionTypes.GET_REPAYMENTS_FAILURE:
      return {
        ...state,
        repayments: {
          ...state.repayments,
          loading: false,
          error: action.error,
        },
      };

    case actionTypes.GET_LOANS_START:
      return {
        ...state,
        loans: {
          ...state.loans,
          loading: true,
          error: null,
        },
      };

    case actionTypes.GET_LOANS_SUCCESS:
      return {
        ...state,
        loans: {
          ...state.loans,
          ...action.payload,
          loading: false,
          error: null,
        },
      };

    case actionTypes.GET_LOANS_FAILURE:
      return {
        ...state,
        loans: {
          ...state.loans,
          loading: false,
          error: action.error,
        },
      };
    case actionTypes.SET_LOAN_FILTER_START_DATE:
      return {
        ...state,
        loans: {
          ...state.loans,
          startDate: action.payload,
        },
      };
    case actionTypes.SET_LOAN_FILTER_END_DATE:
      return {
        ...state,
        loans: {
          ...state.loans,
          endDate: action.payload,
        },
      };
    case actionTypes.SET_LOAN_COUNT:
      return {
        ...state,
        loans: {
          ...state.loans,
          count: action.payload,
        },
      };
    case actionTypes.SET_LOAN_PAGE_NUMBER:
      return {
        ...state,
        loans: {
          ...state.loans,
          pageNumber: action.payload,
        },
      };
    case actionTypes.SET_LOAN_SIZE:
      return {
        ...state,
        loans: {
          ...state.loans,
          size: action.payload,
        },
      };
    case actionTypes.SET_LOAN_IS_DISBURSED:
      return {
        ...state,
        loans: {
          ...state.loans,
          is_disbursed: action.payload,
        },
      };
    case actionTypes.SET_LOAN_IS_APPROVED:
      return {
        ...state,
        loans: {
          ...state.loans,
          is_approved: action.payload,
        },
      };
    case actionTypes.SET_LOAN_STATUS:
      return {
        ...state,
        loans: {
          ...state.loans,
          loan_status: action.payload,
        },
      };
    case actionTypes.SET_LOAN_START_DATE:
      return {
        ...state,
        loans: {
          ...state.loans,
          loanStartDate: action.payload,
        },
      };
    case actionTypes.SET_LOAN_END_DATE:
      return {
        ...state,
        loans: {
          ...state.loans,
          loanEndDate: action.payload,
        },
      };
    case actionTypes.SET_LOAN_ON_MIFOS:
      return {
        ...state,
        loans: {
          ...state.loans,
          IsMigratedtoCBA: action.payload,
        },
      };
    case actionTypes.SET_LOAN_BORROWER_NAME:
      return {
        ...state,
        loans: {
          ...state.loans,
          borrowerName: action.payload,
        },
      };
    case actionTypes.SET_LOAN_ID:
      return {
        ...state,
        loans: {
          ...state.loans,
          loanId: action.payload,
        },
      };
    case actionTypes.SET_LOAN_BVN:
      return {
        ...state,
        loans: {
          ...state.loans,
          bvn: action.payload,
        },
      };
    case actionTypes.SET_LOAN_RC_NUMBER:
      return {
        ...state,
        loans: {
          ...state.loans,
          rcNumber: action.payload,
        },
      };
    case actionTypes.SET_LOAN_AGGREGATOR_ID:
      return {
        ...state,
        loans: {
          ...state.loans,
          aggregatorId: action.payload,
        },
      };
    case actionTypes.SET_LOAN_REFERENCE:
      return {
        ...state,
        loans: {
          ...state.loans,
          loanRef: action.payload,
        },
      };
      case actionTypes.REFETCH_LOANS:
        return {
          ...state,
          disbursements: {
            ...state.disbursements,
            refetch: !state.disbursements.refetch,
          },
        };
    case actionTypes.REMOVE_DISBURSED_LOANS:
      const filteredRecords = state.disbursements.records.filter((row: any) => {
        return (
          !action.payload.loans.includes(row.loan_id.toString()) &&
          !action.payload.loans.includes(row.loan_ref.toString())
        );
      });

      return {
        ...state,
        disbursements: {
          ...state.disbursements,
          records: filteredRecords,
        },
      };
    default:
      return state;
  }
};

export default reducer;
